/* style.css */

nav {
    background-color: #1a1a1a; /* Dark background color */
    color: #ffffff; /* Text color */
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .brand {
    font-size: 1.5rem; /* Adjust the brand name font size */
    font-weight: bold; /* Make the brand name bold */
    cursor: pointer;
    transition: color 0.3s; /* Smooth transition for text color change */
  }
  
  .brand:hover {
    color: #dd6161; /* Change text color on hover for better contrast */
  }
  
  .nav-buttons {
    display: flex;
  }
  
  .nav-buttons button {
    margin-left: 10px;
    padding: 8px 12px;
    cursor: pointer;
    background-color: #184272; /* Button background color */
    color: #ffffff; /* Button text color */
    border: none;
    border-radius: 4px;
    font-size: 1rem; /* Adjust the button font size */
    transition: background-color 0.3s; /* Smooth transition for background color change */
  }
  
  .nav-buttons button:hover {
    background-color: #3678b7; /* Button background color on hover */
    color: #fff; /* Change text color on hover for better contrast */
  }
  
  /* Media query for mobile responsiveness */
  @media screen and (max-width: 600px) {
    .brand {
      font-size: 1.2rem; /* Adjust the brand name font size */
    }
  
    .nav-buttons button {
      margin: 5px;
      padding: 6px 10px; /* Adjusted padding for smaller buttons */
      font-size: 0.8rem; /* Adjusted font size for smaller buttons */
    }
  }
  